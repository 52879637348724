#main {
  background-color: $white;
}

.hero {
  position: relative;
  background-position: 50% 0;
  padding-top: 2rem;
  padding-bottom: 2rem;
  color: $white;
  @include breakpoint(medium) {
    padding-top: 10rem;
    padding-bottom: 10rem;
    color: $body-font-color;
  }
  &:after {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    background-color: rgba($pitch-black, .5);
    width: 100%;
    height: 100%;
    content: "";
    @include breakpoint(medium) {
      display: none;
    }
  }
  &.formtabs {
    @include breakpoint(medium) {
      padding-top: 5rem;
      padding-bottom: 18rem;
    }
  }
  .row {
    position: relative;
    z-index: 1;
  }
  h1 {
    @include breakpoint(small only) {
      font-size: 1.125rem;
    }
  }
  .supplement {
    @include breakpoint(small only) {
      display: block;
      font-size: 1rem;
      font-weight: $global-weight-normal;
    }
    @include breakpoint(medium) {
      &:before {
        display: inline-block;
        margin-right: .25em;
        content: "–";
      }
    }
  }
  h2 {
    margin: .5em 0 0;
    font-size: 1.5rem;
    font-weight: $global-weight-normal;
    @include breakpoint(small only) {
      font-size: 1rem;
    }
  }
  p {
    margin: 1em 0 0;
    @include breakpoint(medium) {
      margin-top: 2em;
    }
  }
}

.panel {
  margin: 0 0 1rem;
  background-color: $primary-color;
  padding: .25rem;
  p {
    margin: 0 0 .5rem;
    &:last-child {
      margin: 0;
    }
  }
  .top {
    background-color: $white;
    padding: 1rem;
    .headline {
      margin-bottom: .5rem;
      color: $primary-color;
    }
  }
  figure {
    background-color: $white;
  }
  .bottom {
    padding: 1rem;
    color: $white;
    a {
      color: $white;
    }
    .large {
      font-size: 1.5rem;
    }
    .xlarge {
      font-size: 2rem;
    }
    .huge {
      line-height: 1;
      font-size: 6rem;
    }
    .light {
      color: rgba($white, .75);
    }
  }
}

.section-formtabs {
  background-color: $light-gray;
  > .row {
    @include breakpoint(small only) {
      @include grid-column-collapse;
      > .column {
        @include grid-column-collapse;
      }
      > header {
        display: none;
      }
    }
    @include breakpoint(medium) {
      position: relative;
      z-index: 10;
      margin-top: -12rem;
    }
  }
  h4 {
    margin-bottom: 1em;
    font-size: 1.5rem;
    .flag {
      margin-left: .25em;
      &:first-child {
        margin-left: 1em;
      }
    }
  }
  h2 {
    font-size: 1.625rem;
  }
  h3 {
    font-size: 1.125rem;
  }
  .accordion {
  }
  .accordion-item {
    border-bottom: 1px solid $light-gray;
  }
  .accordion-title {
    border-radius: 0 !important;
    background-color: rgba($white, .875);
    text-transform: uppercase;
    font-weight: $global-weight-bold;
    color: $medium-gray;
    &:active {
      background-color: $light-gray;
      color: $dark-gray;
    }
    &[aria-selected="true"] {
      background-color: $primary-color;
      color: $white;
    }
    i {
      transform: translateY(-4%);
      display: inline-block;
      margin-right: .25em;
      vertical-align: middle;
      font-size: 1.5em;
    }
    &:before {
      @extend %icon-font;
      transform: rotate(180deg);
      content: $icon-dropdown-pfeil;
    }
  }
  .is-active {
    .accordion-title {
      &:before {
        transform: rotate(0deg);
      }
    }
  }
  .accordion-content {
    @include section-arrow($light-gray);
    padding: 0;
    background-color: $light-gray;
    > .row {
      padding-top: 2rem;
      padding-bottom: 1rem;
      @include breakpoint(small only) {
        @include grid-column-gutter;
        margin: 0;
      }
    }
  }
  .tabs {
    border: 0;
    background: transparent;
  }
  .tabs-title {
    margin-right: 1px;
    text-transform: uppercase;
    font-weight: $global-weight-bold;
    > a {
      background-color: rgba($white, .875);
      &:active {
        background-color: $light-gray;
        color: $dark-gray;
      }
      &[aria-selected="true"] {
        background-color: $primary-color;
      }
      i {
        transform: translateY(-4%);
        display: inline-block;
        margin-right: .25em;
        vertical-align: middle;
        font-size: 1.5em;
        @include breakpoint(medium only) {
          display: none;
        }
      }
    }
  }
  .tabs-content {
    @include section-arrow($light-gray);
    border: 0;
    background-color: $light-gray;
    .tabs-panel {
      padding: 0;
      > .row {
        padding-top: 2rem;
      }
    }
  }
  form {
    @include section-arrow($primary-color);
    background-color: $primary-color;
    fieldset {
      padding: 1rem .625rem;
      @include breakpoint(medium) {
        padding: 2rem 1.25rem;
      }
    }
    .input-text,
    select {
      margin-bottom: .75rem;
      border: 0;
      padding: 1em;
      height: auto;
      line-height: $global-lineheight;
      &.parsley-error {
        background-color: scale-color($alert-color, $lightness: 75%);
      }
      @include breakpoint(medium) {
        margin-bottom: 0;
      }
    }
    .parsley-errors-list {
      margin: 0;
      list-style: none;
      font-size: $small-font-size;
      color: $white;
      &.filled {
        margin: -.25rem 0 .75rem;
        @include breakpoint(medium) {
          margin: 0;
        }
      }
    }
    .button {
      margin-bottom: .75rem;
      border: 0;
      background-color: $black;
      background-image: linear-gradient(rgba($white, .25), rgba($white, 0));
      line-height: $global-lineheight;
      color: $white;
      @include breakpoint(medium) {
        margin-bottom: 0;
      }
    }
  }
}
.section-hotline {
  background-color: $black;
  color: $light-gray;
  h3 {
    margin: 2rem 0;
    text-transform: uppercase;
    font-size: 1rem;
    @include breakpoint(medium) {
      font-size: 1.25rem;
    }
  }
  a {
    color: $light-gray;
  }
}
.section-details {
  @include section-arrow($white);
  background-color: $white;
  padding: 2rem 0;
  @include breakpoint(medium) {
    padding: 4rem 0 5rem;
  }
  h1, h2 {
    font-size: 1.25rem;
    @include breakpoint(medium) {
      font-size: 1.625rem;
    }
  }
  header {
    h1, h2 {
      margin-bottom: 2em;
      text-align: center;
      text-transform: uppercase;
      font-size: 1.375rem;
      color: $mediumlight-gray;
      @include breakpoint(medium) {
        font-size: 1.75rem;
      }
    }
  }
  .accordion {
  }
  .accordion-item {
    border-bottom: 1px solid $light-gray;
  }
  .accordion-title {
    border-radius: 0 !important;
    text-transform: uppercase;
    font-weight: $global-weight-bold;
    color: $medium-gray;
    &:active {
      background-color: $light-gray;
      color: $dark-gray;
    }
    &[aria-selected="true"] {
      background-color: $primary-color;
      color: $white;
    }
    i {
      transform: translateY(-4%);
      display: inline-block;
      margin-right: .25em;
      vertical-align: middle;
      font-size: 1.5em;
    }
    &:before {
      @extend %icon-font;
      transform: rotate(180deg);
      content: $icon-dropdown-pfeil;
    }
  }
  .is-active {
    .accordion-title {
      &:before {
        transform: rotate(0deg);
      }
    }
  }
  .accordion-content {
    padding: 0;
    > .row {
      padding-top: 2rem;
      padding-bottom: 1rem;
      @include breakpoint(medium down) {
        @include grid-column-gutter;
        margin: 0;
      }
    }
  }
  .tabs {
    border: 0;
    text-align: center;
    font-size: $inline-row-font-size;
  }
  .tabs-title {
    float: none;
    display: inline-block;
    border: 1px solid $mediumlight-gray;
    border-left: 0;
    vertical-align: top;
    font-size: $inline-column-font-size;
    &:first-child {
      border-left: 1px solid $mediumlight-gray;
    }
    > a {
      &:active {
        background-color: $light-gray;
        color: $dark-gray;
      }
      &[aria-selected="true"] {
        @include section-arrow($primary-color, .75rem);
        background-color: $primary-color;
      }
      i {
        transform: translateY(-4%);
        display: inline-block;
        margin-right: .25em;
        vertical-align: middle;
        font-size: 1.5em;
      }
    }
  }
  .tabs-content {
    border: 0;
    .tabs-panel {
      padding: 0;
      > .row {
        padding-top: 2rem;
      }
    }
  }
  .boxframe {
    margin: 0;
    border: .25rem solid $primary-color;
    padding: 0;
    @include breakpoint(medium) {
      display: table;
    }
    .column {
      padding: 0;
      @include breakpoint(medium) {
        float: none;
        display: table-cell;
        vertical-align: top;
        &:first-child {
          border-right: .25rem solid $primary-color;
        }
      }
    }
    table {
      th,
      td {
        padding: .75rem 1rem;
        font-size: .875rem;
        @include breakpoint(medium) {
          padding: 1rem 2rem;
          font-size: $body-font-size;
        }
      }
      .boxtrigger {
        th,
        td {
          transition: background-color 250ms, color 250ms;
          cursor: pointer;
          background-color: $white;
          padding: .5rem 1rem;
          color: $body-font-color;
          @include breakpoint(medium) {
            padding: .5rem 2rem;
          }
        }
        @include breakpoint(medium) {
          td {
            &:after {
              transition: transform 250ms, opacity 250ms;
              transform: translate(-100%, -50%);
              position: absolute;
              top: 50%;
              left: 100%;
              z-index: 1;
              display: block;
              margin-left: .25rem;
              opacity: 0;
              border: 0 solid transparent;
              border-left-color: $primary-color;
              width: 0;
              height: 0;
              content: "";
            }
          }
        }
        &.selected {
          th,
          td {
            background-color: $primary-color;
            color: $white;
          }
          @include breakpoint(medium) {
            td {
              position: relative;
              &:after {
                transform: translate(0, -50%);
                opacity: 1;
                border-width: 1rem;
              }
            }
          }
        }
      }
    }
    .box {
      transition: transform 500ms, opacity 500ms;
      transform: translateY(-2rem);
      overflow: hidden;
      visibility: hidden;
      opacity: 0;
      height: 0;
      &.selected {
        transform: translateY(0);
        z-index: 1;
        visibility: visible;
        opacity: 1;
        padding: 1rem;
        height: auto;
        @include breakpoint(medium) {
          padding: 2rem 2rem 2rem 4rem;
        }
      }
    }
  }
}
.section-cta {
  @include section-arrow($primary-color);
  background-color: $primary-color;
  padding: 2rem 0;
  color: $white;
  @include breakpoint(medium) {
    padding: 4rem 0;
  }
  h1,
  h2 {
    margin-bottom: 1em;
    font-size: 1.25rem;
    @include breakpoint(medium) {
      font-size: 1.625rem;
    }
  }
  p {
    @include breakpoint(medium) {
      font-size: 1.125rem;
    }
  }
}
.section-rewards {
  padding: 2rem 0;
  @include breakpoint(medium) {
    padding: 4rem 0;
  }
  h1, h2 {
    margin-bottom: 2rem;
    font-size: 1.25rem;
    @include breakpoint(medium) {
      margin-bottom: 3rem;
      font-size: 1.5rem;
    }
  }
  .column-block {
    &:last-child {
      clear: none;
      float: left;
    }
  }
  &.inside-viewport {
    .column-block {
      animation: itl-entree 1s both;
      @for $i from 1 through 10 {
        &:nth-child(#{$i}) {
          animation-delay: (250ms * $i);
        }
      }
    }
  }
}
@keyframes itl-entree {
  0% {
    transform: translateX(1rem);
    opacity: 0;
  }
  100% {
    transform: translateX(0rem);
    opacity: 1;
  }
}
.section-copy {
  background-color: $light-gray;
  padding: 2rem 0;
  @include breakpoint(small only) {
    padding-bottom: 0;
  }
  @include breakpoint(medium) {
    position: relative;
    padding: 3rem 0;
  }
  h1, h2 {
    font-size: 1.25rem;
    @include breakpoint(medium) {
      font-size: 1.625rem;
    }
  }
  .wallpaper-left,
  .wallpaper-right {
    position: relative;
    @include breakpoint(small only) {
      @include grid-column-row();
      margin-top: 1rem;
      height: 14rem;
    }
  }
  .wallpaper-left {
    @include breakpoint(medium) {
      position: absolute;
      top: 0;
      left: 0;
      width: 50%;
      height: 100%;
    }
  }
  .wallpaper-right {
    @include breakpoint(medium) {
      position: absolute;
      top: 0;
      left: 50%;
      width: 50%;
      height: 100%;
    }
  }
  .map-container {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 100%;
  }
  .pin-info {
    h3 {
      margin: 0 0 .25rem;
      font-size: .875rem;
    }
    a {
      display: block;
      font-size: .875rem;
    }
  }
  .pin-info {
    h3,
    a {
      @include breakpoint(medium) {
        font-size: 1rem;
      }
    }
  }
  .accordion {
  }
  .accordion-item {
    border-bottom: 1px solid $light-gray;
  }
  .accordion-title {
    border-radius: 0 !important;
    background-color: $white;
    text-transform: uppercase;
    font-weight: $global-weight-bold;
    color: $medium-gray;
    &:active {
      background-color: $light-gray;
      color: $dark-gray;
    }
    &[aria-selected="true"] {
      background-color: $primary-color;
      color: $white;
    }
    i {
      transform: translateY(-4%);
      display: inline-block;
      margin-right: .25em;
      vertical-align: middle;
      font-size: 1.5em;
    }
    &:before {
      @extend %icon-font;
      transform: rotate(180deg);
      content: $icon-dropdown-pfeil;
    }
  }
  .is-active {
    .accordion-title {
      &:before {
        transform: rotate(0deg);
      }
    }
  }
  .accordion-content {
  }
}

.section-form {
  padding: 2rem 0;
  @include breakpoint(medium) {
    padding: 4rem 0;
  }
  header {
    h1, h2 {
      font-size: 1.25rem;
    }
    .form-steps {
      display: none;
      visibility: hidden;
    }
    @include breakpoint(medium) {
      counter-reset: item;
      .form-steps {
        .no-js & {
          display: none;
        }
        @include inline-row;
        position: relative;
        margin: 0 0 2rem;
        border-bottom: 1px solid $mediumlight-gray;
        list-style: none;
        padding: 4rem 0 2rem;
        &:before {
          position: absolute;
          top: 2rem;
          left: 0;
          margin-top: -.0625rem;
          background-color: $medium-gray;
          width: 100%;
          height: .1875rem;
          content: "";
        }
      }
    }
    li {
      @include breakpoint(medium) {
        transition: font-weight 500ms, color 500ms;
        @include inline-column;
        position: relative;
        counter-increment: item;
        padding-top: 1rem;
        vertical-align: bottom;
        text-align: center;
        color: $medium-gray;
        &:before {
          transition: border 500ms, font-size 500ms, color 500ms;
          transform: translate(-50%, -50%);
          position: absolute;
          left: 50%;
          top: -2rem;
          border-radius: 100%;
          border: .1875rem solid $medium-gray;
          background: $white;
          color: $mediumlight-gray;
          width: 2.5em;
          height: 2.5em;
          content: counter(item);
          line-height: 2.25em;
          font-size: 1.375rem;
          font-weight: $global-weight-bold;
        }
      }
      &.current-step {
        font-weight: $global-weight-bold;
        color: $primary-color;
        @include breakpoint(medium) {
          &:before {
            border-color: $primary-color;
            font-size: 1.75rem;
            color: $primary-color;
          }
        }
      }
    }
  }
  form {
    .no-js & {
      display: none;
    }
    &.row {
      @include breakpoint(small only) {
        padding: 0;
      }
    }
  }
  .form-step {
    transform: translateX(1rem);
    overflow: hidden;
    opacity: 0;
    height: 0;
    @include breakpoint(medium) {
      transition: transform 1s, opacity 1s;
    }
    &.current-step {
      transform: translateX(0rem);
      opacity: 1;
      height: auto;
    }
  }
  legend {
    margin-bottom: .75rem;
  }
  label {
    margin-bottom: .75rem;
  }
  .repeat {
    margin-bottom: 1rem;
    border-bottom: 1px solid $mediumlight-gray;
    padding-bottom: 1rem;
    .repeat-remove {
      display: none;
    }
    &:last-of-type {
      .repeat-remove {
        display: block;
      }
    }
    &:first-of-type {
      .repeat-remove {
        display: none;
      }
    }
  }
  .input-text,
  select {
    margin-bottom: 1rem;
    padding: 1em;
    height: auto;
    line-height: $global-lineheight;
    &.parsley-error {
      border-color: $alert-color;
    }
  }
  .parsley-errors-list {
    list-style: none;
    font-size: $small-font-size;
    color: $alert-color;
    &.filled {
      margin: -.5rem 0 1rem;
    }
  }
  .button-group {
    overflow: hidden;
    border-radius: $global-radius;
    border: 1px solid $mediumlight-gray;
    label {
      margin: 0;
    }
    .button {
      border-radius: 0;
      border-right: 1px solid $mediumlight-gray;
      padding: 1em 0;
      line-height: $global-lineheight;
      text-transform: none;
      font-size: .75rem;
      font-weight: $global-weight-normal;
      &:last-child {
        border-right: 0;
      }
      @include breakpoint(medium) {
        padding: 1em;
        font-size: $body-font-size;
      }
    }
    &.expanded {
      display: table;
      table-layout: fixed;
      width: 100%;
      .button {
        display: table-cell;
      }
    }
    [type='radio'] {
      display: none;
      + .button {
        @include button-style($white, $button-background-hover, $medium-gray);
      }
      &:checked {
        + .button {
          @include button-style($primary-color, $button-background-hover, $white);
          background-image: linear-gradient(rgba($white, .25), rgba($white, 0));
        }
      }
    }
  }
  button {
    &.button {
      margin-top: 1em;
      &.label-margin {
        margin-top: 2.125rem; // 1rem * 1.375 + 0.75rem
      }
    }
  }

}

#contactModal {
  @include breakpoint(medium) {
    width: 60%;
  }
  @include breakpoint(large) {
    width: 40%;
  }
  header {
    h1, h2 {
      font-size: 1.25rem;
      @include breakpoint(medium) {
        font-size: 1.5rem;
      }
    }
    @include breakpoint(medium) {
      padding: 1.5rem 2rem 1rem;
    }
  }
  .body {
    @include breakpoint(medium) {
      padding: 1.5rem 1rem 1rem;
    }
    .input-text,
    select {
      margin-bottom: 1rem;
      border: 0;
      padding: 1em;
      height: auto;
      line-height: $global-lineheight;
      &.parsley-error {
        background-color: scale-color($alert-color, $lightness: 75%);
      }
    }
    .parsley-errors-list {
      margin: 0;
      list-style: none;
      font-size: $small-font-size;
      color: $white;
      &.filled {
        margin: -.75rem 0 1rem;
      }
    }
    .button {
      margin-bottom: 1rem;
      border: 0;
      line-height: $global-lineheight;
      color: $white;
    }
  }
}