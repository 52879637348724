$inline-row-font-size: 0;
$inline-column-font-size: $body-font-size;

@mixin section-arrow($color: transparent, $size: 1rem) {
  position: relative;
  &:after {
    transform: translateX(-50%);
    position: absolute;
    top: 100%;
    left: 50%;
    z-index: 1;
    display: block;
    border: $size solid transparent;
    border-top-color: $color;
    width: 0;
    height: 0;
    content: "";
  }
}

.row {
  @include breakpoint(small only) {
    @include grid-column-gutter;
  }
}

@mixin inline-row() {
  @include grid-row;
  @include breakpoint(small only) {
    @include grid-column-gutter;
  }
  font-size: $inline-row-font-size;
}
.inline-row {
  @include inline-row;
}

@mixin inline-column() {
  @include grid-column-gutter;
  display: inline-block;
  vertical-align: top;
  font-size: $inline-column-font-size;
}
.inline-column {
  @include inline-column;
}

.wallpaper {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

select {
  box-shadow: inset 0 .125em .125em rgba($black, 0.375);
  background-image: url("data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22500%22%20height%3D%22500%22%20viewBox%3D%220%200%20500%20500%22%3E%0D%0A%20%20%3Cdefs%3E%0D%0A%20%20%20%20%3Cstyle%3E%0D%0A%20%20%20%20%20%20.cls-1%20%7B%0D%0A%20%20%20%20%20%20%20%20fill-rule%3A%20evenodd%3B%0D%0A%20%20%20%20%20%20%7D%0D%0A%20%20%20%20%3C%2Fstyle%3E%0D%0A%20%20%3C%2Fdefs%3E%0D%0A%20%20%3Cpath%20id%3D%22Pfeil%22%20class%3D%22cls-1%22%20fill%3D'%23999999'%20d%3D%22M442,172.919L397.2,125.7,250,280.849,102.8,125.7,58,172.919,250,375.3Z%22%2F%3E%0D%0A%3C%2Fsvg%3E%0D%0A");
  background-size: 1rem auto;
}

.button {
  text-transform: uppercase;
  font-weight: $global-weight-bold;
  &.secondary {
    @include button-style($secondary-color, scale-color($secondary-color, $lightness: -15%), $white);
  }
  &.neutral {
    @include button-style($mediumlight-gray, $light-gray, $dark-gray);
    &.hollow {
      @include button-style($white, $white, $mediumlight-gray);
      border: 1px solid $mediumlight-gray;
    }
  }
}

.reveal {
  header {
    padding: 1rem;
    text-align: center;
    @include breakpoint(medium) {
      padding: 1.5rem 2rem 1rem;
    }
    .deco {
      font-size: 2rem;
      @include breakpoint(medium) {
        font-size: 3rem;
      }
    }
  }
  .body {
    background-color: $primary-color;
    padding: 1rem;
    color: $white;
    @include breakpoint(medium) {
      padding: 1.5rem 2rem 1rem;
    }
  }
}

.label {
  text-transform: uppercase;
}

.color-primary {
  color: $primary-color;
}
.color-alert {
  color: $alert-color;
}
.text-125 {
  font-size: 1.25em;
}
.text-150 {
  font-size: 1.5em;
}
.text-200 {
  font-size: 2em;
}

.flag {
  position: relative;
  display: inline-block;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: (4 / 3) * 1em;
  line-height: 1em;
  &:before {
    content: '\00a0';
  }
}
.flag-de {
  background-image: url("data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20height%3D%22480%22%20width%3D%22640%22%20viewBox%3D%220%200%20640%20480%22%3E%0D%0A%20%20%3Cpath%20fill%3D%22%23ffce00%22%20d%3D%22M0%20320h640v160.002H0z%22%2F%3E%0D%0A%20%20%3Cpath%20d%3D%22M0%200h640v160H0z%22%2F%3E%0D%0A%20%20%3Cpath%20fill%3D%22%23d00%22%20d%3D%22M0%20160h640v160H0z%22%2F%3E%0D%0A%3C%2Fsvg%3E%0D%0A");
}
.flag-ch {
  background-image: url("data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20height%3D%22480%22%20width%3D%22640%22%20viewBox%3D%220%200%20640%20480%22%3E%0D%0A%20%20%3Cg%20fill-rule%3D%22evenodd%22%20stroke-width%3D%221pt%22%3E%0D%0A%20%20%20%20%3Cpath%20fill%3D%22%23d52b1e%22%20d%3D%22M0%200h640v480H0z%22%2F%3E%0D%0A%20%20%20%20%3Cg%20fill%3D%22%23fff%22%3E%0D%0A%20%20%20%20%20%20%3Cpath%20d%3D%22M170%20194.997h299.996v89.997H170z%22%2F%3E%0D%0A%20%20%20%20%20%20%3Cpath%20d%3D%22M275%2089.997h89.996v299.996H275z%22%2F%3E%0D%0A%20%20%20%20%3C%2Fg%3E%0D%0A%20%20%3C%2Fg%3E%0D%0A%3C%2Fsvg%3E%0D%0A");
}

ul {
  &.checks {
    margin: 2rem 0;
    list-style: none;
    li {
      position: relative;
      margin: 0 0 .5rem;
      padding-left: 2rem;
      &:before {
        @extend %icon-font;
        content: $icon-bulletpoint;
        position: absolute;
        top: 0;
        left: 0;
        font-size: 1.5em;
        color: $primary-color;
      }
      ul.checks {
        margin: .5rem 0 0 0;
      }
    }
  }
}

table {
  border-collapse: collapse;
  border-radius: 0;
  border: 0;
  width: 100% !important;
  th {
    text-align: left;
    font-weight: $global-weight-normal;
  }
  th,
  td {
    &.strong {
      font-weight: $global-weight-bold;
    }
  }
  tr {
    > * {
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
    }
    &.separator {
      > * {
        border-bottom: 1px solid $dark-gray;
      }
    }
    &.result {
      > * {
        border-top: 2px solid $primary-color;
        color: $primary-color;
      }
    }
  }
  thead,
  tfoot {
    background-color: transparent;
    color: $body-font-color;
    th,
    td {
      font-weight: $global-weight-normal;
    }
  }
  thead {
    th,
    td {
      padding-bottom: 0;
    }
  }
}

.pad-x {
  @include breakpoint(medium) {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
.pad-y {
  @include breakpoint(medium) {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}

#itlYEO {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  background-image: radial-gradient(hsla(0, 0%, 0%, 0.5), hsla(0, 0%, 0%, 0.9375));
  padding: 1rem;
  .content {
    position: relative;
    margin: 0 auto;
    max-width: 60rem;
    height: 100%;
  }
}
#itlYEOclickmask {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  background-color: white;
  padding: 0 0 percentage(9/16);
  height: 0;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
  }
}
#itlYEOClose {
  position: absolute;
  top: .5rem;
  right: .5rem;
  z-index: 1;
  cursor: pointer;
  outline: 0;
  background-color: hsla(0, 0%, 0%, 0.25);
  width: 2rem;
  height: 2rem;
  text-align: left;
  text-indent: -9999em;
  &:before,
  &:after {
    position: absolute;
    top: 10%;
    bottom: 10%;
    left: 50%;
    margin-left: -1px;
    background-color: hsla(0, 0%, 100%, 0.875);
    width: .125rem;
    content: '';
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
  &:hover {
    &:before,
    &:after {
      top: 0;
      bottom: 0;
      background-color: hsla(0, 0%, 100%, 1);
    }
  }
}


.itl-spinner {
  position: relative;
  opacity: 1 !important;
  color: transparent !important;
  &:before {
    position: absolute;
    top: 50%;
    left: 50%;
    animation: deadOrAlive 1s infinite linear;
    display: block;
    margin: -.75em 0 0 -.75em;
    border-left: .1875em solid rgba($white, .875);
    border-right: .1875em solid rgba($white, .875);
    border-bottom: .1875em solid rgba($white, .875);
    border-top: .1875em solid rgba($white, .5);
    border-radius: 100%;
    height: 1.5em;
    width: 1.5em;
    content: "";
  }
}
@keyframes deadOrAlive {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
