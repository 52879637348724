$icon-bulletpoint: "\e900";
$icon-dropdown-pfeil: "\e901";
$icon-info: "\e902";
$icon-krankenversicherung: "\e903";
$icon-lohnrechner: "\e904";
$icon-pfeil: "\e905";
$icon-phone: "\e906";
$icon-pin: "\e907";
$icon-play: "\e908";
$icon-steuernsparen: "\e909";

@font-face {
  font-family: 'ggvsslp';
  src:
          url('../fonts/ggvsslp.ttf?e00h82') format('truetype'),
          url('../fonts/ggvsslp.woff?e00h82') format('woff'),
          url('../fonts/ggvsslp.svg?e00h82#ggvsslp') format('svg');
  font-weight: normal;
  font-style: normal;
}

%icon-font {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'ggvsslp' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"], [class*=" icon-"] {
  @extend %icon-font;
}

.icon-bulletpoint {
  &:before {
    content: $icon-bulletpoint;
  }
}
.icon-dropdown-pfeil {
  &:before {
    content: $icon-dropdown-pfeil;
  }
}
.icon-info {
  &:before {
    content: $icon-info;
  }
}
.icon-krankenversicherung {
  &:before {
    content: $icon-krankenversicherung;
  }
}
.icon-lohnrechner {
  &:before {
    content: $icon-lohnrechner;
  }
}
.icon-pfeil {
  &:before {
    content: $icon-pfeil;
  }
}
.icon-phone {
  &:before {
    content: $icon-phone;
  }
}
.icon-pin {
  &:before {
    content: $icon-pin;
  }
}
.icon-play {
  &:before {
    content: $icon-play;
  }
}
.icon-steuernsparen {
  &:before {
    content: $icon-steuernsparen;
  }
}

