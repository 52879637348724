#header {
  box-shadow: 0 0 .25rem rgba($black, .25);
  border-top: .25rem solid $primary-color;
  background: $white;
  .row {
    position: relative;
  }
  .logo {
    display: block;
    padding: 1rem;
    text-align: center;
    @include breakpoint(medium) {
      float: left;
      text-align: left;
    }
    img {
      width: auto;
      height: 2rem;
      @include breakpoint(medium) {
        height: 4rem;
      }
    }
  }
  .contact {
    transform: translateY(-50%);
    position: absolute;
    top: 50%;
    left: 0;
    padding: 1rem;
    font-size: $body-font-size;
    color: $dark-gray;
    @include breakpoint(medium) {
      transform: translateY(0);
      position: relative;
      top: auto;
      left: auto;
      float: right;
      padding-top: 1.5rem;
      text-align: right;
      font-size: 1.5rem;
    }
    .type {
      display: none;
      @include breakpoint(medium) {
        display: inline-block;
        font-weight: $global-weight-bold;
        i {
          vertical-align: middle;
          font-size: 1.125em;
          color: $primary-color;
        }
      }
    }
    a {
      display: block;
      outline: 0;
      @include breakpoint(medium) {
        display: inline-block;
        text-indent: 0;
        font-weight: $global-weight-bold;
      }
      i {
        vertical-align: middle;
        font-size: 2rem;
        color: $medium-gray;
      }
    }
    small {
      display: none;
      @include breakpoint(medium) {
        display: block;
        font-size: .875rem;
        color: $medium-gray;
      }
    }
  }
  .noscript {
    background-color: #fcc;
    padding: .25rem 1rem;
    text-align: center;
    font-size: .875rem;
    color: #c33;
  }


  // Hamburger Button
  .hamburger {
    display: inline-block;
    overflow: visible;
    position: absolute;
    top: 50%;
    right: 0;
    margin: 0;
    padding: 1rem;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    transform: translateY(-50%);

    @include breakpoint(medium) {
      display: none;
    }
  }
  .hamburger-box {
    height: 24px;
    display: inline-block;
    position: relative;
    width: 40px;
  }
  .hamburger-inner {
    display: block;
    margin: -2px;
    position: relative;
    top: 50%;
    &,
    &:before,
    &:after {
      background-color: $medium-gray;
      border-radius: 4px;
      content: "";
      display: block;
      height: 4px;
      position: absolute;
      width: 40px;
      transition-property: transform;
      transition-duration: 0.15s;
      transition-timing-function: ease;
    }
    &:before {
      top: -10px;
    }
    &:after {
      bottom: -10px;
    }
  }
  /* Squeeze */
  .hamburger--squeeze {
    .hamburger-inner {
      transition-duration: 0.1s;
      transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
      &:before {
        transition: top 0.1s 0.14s ease, opacity 0.1s ease;
      }
      &:after {
        transition: bottom 0.1s 0.14s ease, transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19);
      }
    }
  }
  .hamburger--squeeze.is-active {
    .hamburger-inner {
      transform: rotate(45deg);
      transition-delay: 0.14s;
      transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      &:before {
        top: 0;
        opacity: 0;
        transition: top 0.1s ease, opacity 0.1s 0.14s ease;
      }
      &:after {
        bottom: 0;
        transform: rotate(-90deg);
        transition: bottom 0.1s ease, transform 0.1s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1);
      }
    }
  }

  // Mobile Nav
  .burger-nav {
    display: none;
    ul {
      list-style: none;
      margin: rem-calc(10 0);
      li {
        border-top: 1px solid $mediumlight-gray;
        a {
          display: block;
          padding: rem-calc(12 0);
          font-weight: bold;
          text-transform: uppercase;
          text-align: center;
          &.active {
            background-color: $primary-color;
            color: $white;
          }
        }
      }
    }
  }
}
