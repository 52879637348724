@charset "UTF-8";

// Fonts
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,400i,700');

/**
 * Load settings
 */
@import 'settings/settings';

/**
 * Foundation for Sites by ZURB
 * Version 6.3.0
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
@import 'foundation';

// Foundation components
@include foundation-global-styles;
@include foundation-grid;
@include foundation-typography;
@include foundation-forms;
@include foundation-button;
@include foundation-accordion;
//@include foundation-accordion-menu;
//@include foundation-badge;
//@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
//@include foundation-card;
@include foundation-close-button;
//@include foundation-menu;
//@include foundation-menu-icon;
//@include foundation-drilldown-menu;
//@include foundation-dropdown;
//@include foundation-dropdown-menu;
//@include foundation-responsive-embed;
@include foundation-label;
//@include foundation-media-object;
//@include foundation-off-canvas;
//@include foundation-orbit;
//@include foundation-pagination;
//@include foundation-progress-bar;
//@include foundation-slider;
//@include foundation-sticky;
@include foundation-reveal;
//@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
//@include foundation-thumbnail;
//@include foundation-title-bar;
//@include foundation-tooltip;
//@include foundation-top-bar;
@include foundation-visibility-classes;
@include foundation-float-classes;

// or include hole foundation mixins
// flex grid true or false
// @include foundation-everything(false);

/*
 * Custom styles
 */
@import "icons";
@import "basics";
// @import "slick";

@import "header";
@import "main";
@import "footer";

