#footer {
  background-color: $black;
  .addresses {
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-size: 0; /* Avoid white-space gap with inline-block child elements -> needs to be reset for child element */
    color: $light-gray;
    @include breakpoint(medium) {
      padding: 3rem 0 1rem;
    }
    p {
      margin: 1rem 0;
      font-size: .875rem;
    }
    a {
      text-decoration: underline;
      color: $light-gray;
    }
    strong {
      display: block;
      margin-bottom: .25em;
      text-transform: uppercase;
      font-size: 1rem;
      &:before {
        @extend %icon-font;
        display: none;
        @include breakpoint(medium) {
          display: inline-block;
          margin-right: .125em;
          text-indent: -.125em;
          content: $icon-pin;
          vertical-align: middle;
          font-size: 2em;
          color: $primary-color;
        }
      }
    }
  }
  .claim {
    padding-bottom: 1rem;
    text-align: center;
    color: $medium-gray;
    hr {
      margin: 1.5rem 0;
    }
    p {
      margin-bottom: .5rem;
      font-size: 1.25rem;
      font-style: italic;
    }
  }
  .legal {
    background-color: $pitch-black;
    padding-top: 1rem;
    padding-bottom: 1rem;
    text-align: center;
    color: $medium-gray;
    nav {
      @include breakpoint(medium) {
        display: inline-block;
      }
    }
    ul {
      margin: .5rem 0 0;
      list-style: none;
      padding: 0;
      @include breakpoint(medium) {
        display: inline-block;
        margin: 0;
        font-size: 0; /* Avoid white-space gap with inline-block child elements -> needs to be reset for child element */
      }
      li {
        margin: .5em 0 0;
        @include breakpoint(medium) {
          display: inline-block;
          margin: 0 0 0 .5em;
          font-size: .875rem;
          &:before {
            display: inline-block;
            margin-right: .5em;
            content: "·";
          }
        }
      }
    }
    a {
      color: $medium-gray;
    }
  }
}